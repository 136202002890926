@import 'src/assets/scss/mixins';

.Header {
  flex-flow: row nowrap;
  row-gap: 20px;

  @include Tablet {
    flex-flow: row wrap;
  }

  .LogoWithMerchants {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-right: 60px;
    flex-direction: row;
    width: auto;

    @include Mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0;
      width: 100%;
    }
  }

  .Dropdown {
    position: relative;

    .NotificationToggle {
      cursor: pointer;
      position: relative;


      &.Active, &:hover {
        fill: var(--color-main);
      }

    }
    .RedBlob {
      display: none;
      position: absolute;
      top: 3px;
      right: 5px;
      width: 7px;
      height: 7px;
      background-color: var(--color-error);
      border-radius: 99px;
      border: 1px solid white;
      &.Visible {
        display: block;
      }
    }
    
    .NotificationDropdownHeader {
      display: flex;
      gap: 10px;
      align-items: center;
      @include Tablet {
        flex-wrap: wrap;
      }

      >button {
        color: var(--color-primary300);
      }
      
      .Link {
        background-color: var(--color-main);
        color: black;
        padding: 5px 10px;
        border-radius: 99px;
        text-decoration: none;
        margin-left: 10px;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      margin-top: 20px;
      max-height: 50vh;
      overflow-y: auto;

      

      li {
        padding: 10px 20px;
        border-radius: 10px;
        background-color: black;
        white-space: wrap;
        border: 1px solid transparent;

        &.IsNew {
          border-color: var(--color-main); 
          p {
            &:first-child {
              color: var(--color-main);
            }
          }
        }
        
        p {
          &:first-child {
            margin-bottom: 10px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;
          @include Mobile {
            margin-bottom: 10px;
          }

        }
        
      }
    }
  }
}