.input-wrapper {
  label {
    color: $white;
    font-size: 1em;
  }

  .input {
    background-color: $main;
    border: 1px solid transparent;
    transition: all 1ms ease-in-out;

    &:hover {
      border: 1px solid $white;
    }

    &:focus {
      border: 1px solid $main;
    }

    &.hasError {
      border: 1px solid $error;
    }
  }

  .error-msg {
    color: $error;
    font-size: 0.8em;
  }
}

.form-input {
  &.hasError {
    & .form-control {
      border-color: $error;
      border-width: 1px;
    }
  }

  & .form-control {
    color: $white;
    background: $primary700;
    border-color: $primary400;

    &:hover {
      border: 1px solid $white;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $primary400;
      opacity: 1; /* Firefox */
    }
  }

  .field-error {
    color: $error;
    font-size: 12px;
    margin-top: 5px;
  }
}

.otp-input-container {
  display: flex;
  justify-content: center;
  span {
    margin-inline: 15px;
    @media screen and (max-width: 600px){
      margin-inline: 3px;
    }
  }
}

.otp-input {
  width: 40px !important;
  height: 40px;
  line-height: 20px !important;
  border-radius: 5px;
  box-shadow: none;
  border-color: $primary400;
  border-width: 1px;
  @media screen and (max-width: 600px){
    width: 40px !important;
    height: 40px;
    line-height: 20px !important;
  }
}
