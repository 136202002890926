@import "src/assets/scss/mixins";

.NotificationsPage {
  h3 {
    margin-bottom: 10px;
  }

  .Heading {
    display: flex;
    gap: 20px;

    .NotificationsHeading {
      width: 60%;
    }

    .AlertChannelsHeading {
      display: flex;
      gap: 50px;

      >button {
        border-radius: 99px;
        font-size: 12px;
        line-height: 16.8px;
        padding-block: 4px;
        padding-inline: 10px;
        min-height: auto;
        height: fit-content;
      }

      >h3 {
        @include Mobile {
          display: none;
        }
      }
    }

    margin-bottom: 25px;
  }

  .Settings {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .Setting {
      display: flex;
      align-items: center;
      gap: 20px;

      @include Mobile {
        flex-direction: column;
      }

      &:first-child {
        align-items: flex-end;

        .AlertChannels {
          gap: 20px;

        }
      }

      &:not(:first-child) {
        .AlertChannels  {
          gap: 0;

          >p {
            height: 0;
            visibility: hidden;

            @include Mobile {
              height: auto;
              visibility: visible;
            }
          }

        }
      }

      .Type {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 60%;

        @include Mobile {
          width: 100%;
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .BorderCount {
          flex: 1;
        }
      }

      .Channels {
        display: flex;
        gap: 50px;

        @include Mobile {
          width: 100%;
          gap: 30px;
          flex-wrap: wrap;
        }

        .AlertChannels {
          display: flex;
          flex-direction: column;

          @include Mobile {
            gap: 5px;
            flex-direction: row-reverse;
          }
        }
      }

      .Description {
        max-width: 560px;
      }

      .SetupBorderButton {
        border-radius: 99px;
        font-size: 8px;
        line-height: 11.2px;
        padding-block: 7px;
        padding-inline: 8.5px;
        min-height: auto;
      }
    }

    
  } 
}