@import '../../assets/scss/mixins';

.Input {
  background: var(--color-primary700);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 50px;
  color: var(--color-white);
  border: 1px solid transparent;

  &:hover {
    border-color: var(--color-white);
  }

  &:focus {
    border-color: var(--color-main);
  }

  &:disabled {
    background: var(--color-primary900);
    cursor: not-allowed;
  }

  &-Context {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: baseline;
    min-height: 30px;

    @include Mobile {
      margin-bottom: 0;
      min-height: 25px;
    }
  }

  &.__wide {
    width: 100%;
  }

  &.__error {
    border-color: var(--color-error);
  }
}

.tooltip {
  &-inner {
    background-color: var(--color-white);
    color: var(--color-background);
    font-size: 16px;
    max-width: 400px;

    @include Mobile {
      max-width: 200px;
    }
  }

  &-arrow {
    &::before {
      border-right-color: var(--color-white) !important;
    }
  }
}
