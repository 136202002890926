@import '../../assets/scss/themes/colors.module';
@import '../../assets/scss/mixins';

.ReportsPage {
  display: flex;
  align-items: center;

  @include Mobile {
    overflow: scroll;
    white-space: nowrap;
  }

  h3 {
    margin-right: 40px;
  }

  .menu-button {
    display: flex;
    flex-direction: column;
    line-height: 45px;
    padding: 0 10px;
    margin-right: 15px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-size: 1.1em;
    color: #fff;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: $main;
      color: $mainBackground;

      &:hover {
        background-color: $main700;
      }
    }

    &:hover {
      background-color: $primary100;
      color: $mainBackground;
    }
  }

  // the menu button should come from somewhere externally, so as of now just adding more styles here
  .menu-button {
    border: 1px solid $primary;
    line-height: 35px;

    &.active {
      border-color: var(--color-main);
    }
  }
}
