@import 'src/assets/scss/mixins';

.Dropdown {
  position: relative;

  

  .Toggle {
    cursor: pointer;
  }

  .Content {
    display: none;
    position: absolute;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--color-primary800);
    top: auto;
    margin-top: 10px;
    z-index: 1;
    box-shadow: 0 4px 4px 0 #00000040;
    white-space: nowrap;

    &.Visible {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &.FlowLeft {
      left: 0;
    }

    &.FlowRight {
      right: 0;
    }

    .List {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 0;

      li {
        border-radius: 10px;
        padding-inline: 10px;
        padding-block: 5px;
        background-color: var(--color-primary900);
        cursor: pointer;
        border: 1px solid transparent;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: var(--color-primary600);
        }
      }
    }
  }

  
}