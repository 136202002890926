.DepositLimit {
  border-radius: 10px;
  background: var(--color-primary700);
  padding: 20px;
  padding-left: 50px;

  .List {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    li {
      &:before {
        content: "·";
        vertical-align: middle;
        line-height: 14px;
        font-size: 32px;
        margin-right: 10px;
      }
    }
  }
}