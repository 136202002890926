.TransactionReports {
  button {
    display: flex;
    margin: 0;
    line-height: initial;
    border-radius: 20px;
  }

  &-Actions {
    margin-bottom: 30px;

    button {
      min-height: 45px;
      border-radius: 5px;
      margin-left: auto;
    }
  }
}
