@import 'src/assets/scss/mixins';

.RedirectPaymentsPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-block: 40px;

  p,h4 {
    text-align: center;
  }

  &-QRWrapper {
    display: flex;
    background: white;
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 20px;
  }

  &-Info {
    display: flex;
    padding-inline: 30%;

    @include Mobile {
      padding-inline: 10%;
    }

    svg {
      width: 80px;
    }
  }

  p {
    font-size: small;
  }

  ol {
    list-style-type: disc;
    background-color: var(--color-primary700);
    border-radius: 10px;
    white-space: nowrap;
    padding: 20px;

    li {
      list-style-position: inside;
      text-align: center;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.container-fluid:has(.RedirectPaymentsPage) {
  display: flex;
  justify-content: center;
}
