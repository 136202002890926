@import '../../assets/scss/mixins';

.CopyTextComponent {
  max-width: 100%;

  @include Mobile {
    max-width: 300px;
  }

  &-Content {
    display: flex;
    background-color: var(--color-primary700);
    height: 50px;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    gap: 10px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: small;
    white-space: nowrap;
  }

  button {
    margin-left: auto;
    border-radius: 20px;
    margin-right: 0;
  }

  &-AddressCopied {
    margin-top: 10px;
    text-align: center;
    font-size: 0.8rem;
  }
}
