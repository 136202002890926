@import "colors.module";
@import "breakpoint.module";
@import 'typography';

//
$colors-list: (
  'mainBackground' : $mainBackground,
  'error' : $error,
  'success' : $success,
  'warning' : $warning,
  'additional' : $additional,
  'white' : $white,
  'main' : $main,
  'main100' : $main100,
  'main200' : $main200,
  'main300' : $main300,
  'main400' : $main400,
  'main600' : $main600,
  'main700' : $main700,
  'main800' : $main800,
  'main900' : $main900,
  'primary' : $primary,
  'primary100' : $primary100,
  'primary200' : $primary200,
  'primary300' : $primary300,
  'primary400' : $primary400,
  'primary600' : $primary600,
  'primary700' : $primary700,
  'primary800' : $primary800,
  'primary900' : $primary900
);

@each $name, $current-color in $colors-list {
  //.#{$current-color}-bg {
  //  background-color: $current-color;
  //}
  .color-#{$name} {
    color: #{$current-color};
  }
}

:export {
  main: $main;
}
