@import 'src/assets/scss/mixins';

.Layout {
  padding-top: 25px;
  padding-bottom: 60px;
  padding-inline: 90px;
  
  @include Tablet {
    padding-inline: 45px;
  }
  
  @include Mobile{
    padding-inline: 20px;
  }
}
