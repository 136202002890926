@import 'src/assets/scss/mixins';

.BasicDropdown {
  display: none;
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--color-primary800);
  top: auto;
  margin-top: 10px;
  z-index: 1;
  box-shadow: 0 4px 4px 0 #00000040;
  white-space: nowrap;
  right: 0;

  @include Tablet {
    max-width: 80vw;
  }

  &.Visible {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

}