.LoginPage {
  &-OTPInput {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;

    h2 {
      text-align: center;
    }

    label {
      margin-bottom: 25px;
    }
  }

  button {
    width: 100%;
  }

  &-ButtonGroup {
    display: flex;

    button {
      width: 100%;
    }
  }

  .Input {
    width: 100%;
    margin-bottom: 40px;
  }

  &-ForgotPassword {
    text-decoration: underline;
  }
}
