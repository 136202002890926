@import '../../assets/scss/themes/colors.module';
@import '../../assets/scss/mixins';

.Datepicker {
  display: flex;

  @include Mobile {
    flex-wrap: wrap-reverse;
    margin-bottom: 20px;
  }

  &-Left {
    @include Mobile {
      width: 100%;
    }
  }

  &-Right {
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $primary600;
    padding: 20px;

    @include Mobile {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-Date {
      border: 1px solid $mainBackground;
      border-radius: 10px;
      padding: 5px;
      padding-left: 10px;
      background: $primary700;
    }

    &-Dropdown {
      display: block;
      border: 1px solid $mainBackground;
      border-radius: 10px;
      padding: 5px;
      background: transparent;
      width: 100%;
      padding-left: 0;
    }
  }

  .react-datepicker {
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @include Mobile {
      width: 100%;
    }
  }

  select {
    background: transparent;
    border: 1px solid $mainBackground;
    border-radius: 10px;
    padding: 5px;
    color: $white;
    padding-left: 10px;
  }
}
