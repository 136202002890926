@import '/src/assets/scss/mixins';

.MagicSearch {
  position: relative;
  width: fit-content;

  @include Mobile {
    width: auto
  }

  >input {
    @include BorderBox;

    transition: border-color 300ms ease;
    min-height: 35px;
    background-color: transparent;
    color: var(--color-primary300);
    padding-left: 35px;
    padding-right: 30px;
    width: 40vw;
    font-size: 14px;

    @include Mobile {
      width: 100%;
    }
  }

  .SearchIcon {
    position: absolute;
    top: 6px;
    left: 8px;
    stroke: var(--color-primary300);
  }

  .CloseIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    left: initial;
  }

  &:hover {
    >input {
      border-color: var(--color-main200);
    }
  }

  &.Active {
    >input {
      border-color: var(--color-main);
      color: var(--color-white);
    }

    .SearchIcon {
      stroke: var(--color-white);
    }
  }
}