@import 'src/assets/scss/mixins';

.NewPasswordAfterReset {
  .PasswordRequirements {
    @include ValidationCircle;

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: small;
    margin-bottom: 5px;
    max-width: 200px;
    align-content: center;


    & p{
      text-align: left;
      margin: 0;
    }

    &.Complete {
      @include ValidationSuccessCircle;
    }

    &.Error {
      @include ValidationErrorCircle;
    }
  }
}