@import 'src/assets/scss/mixins';

.MerchantsDropdown{
  position: relative;
  width: 240px;

  @include Mobile {
    width: 100%;
  }

  .Content {
    border: 1px solid white;
    padding-inline: 10px;
    padding-block: 11px;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: auto;
    max-width: 240px;
    
    @include Mobile {
      width: 100%;
      max-width: 100%;

    }
  }

  .Dropdown {
    display: none;
    position: absolute;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--color-primary800);
    top: auto;
    left: 0;
    width: 100%;
    margin-top: 5px;
    z-index: 1;
    


    &.Visible {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .DropdownItem {
      border-radius: 10px;
      padding-inline: 10px;
      padding-block: 5px;
      background-color: var(--color-primary900);
      cursor: pointer;
      border: 1px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: var(--color-primary600);
      }

      &.Active {
        border-color: var(--color-main);
        color: var(--color-main);
      }
    }
  }

  .RunningMerchantName {
    display: flex;

    >p {
      overflow: visible;
    }

    &.Scroll {
      animation-duration: 2.5s;
      animation-name: scroll;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    }

  }}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}