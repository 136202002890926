@import '../../assets/scss/mixins';

.BusinessSignUp {
  display: flex;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-background);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-primary700);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  h3,
  p {
    text-align: center;
    margin-bottom: 20px;
  }

  &-Container {
    height: 100vh;
    padding: 50px;
    background: var(--color-main);
    width: 50%;

    @include Mobile {
      padding: 0;
      background: var(--color-background);
      width: 100%;
      margin: 20px;

    }
  }

  &-Container,
  &-Info {
    opacity: 1;

    &.__Success {
      opacity: 0;
      transition: all 300ms ease-in;

      @include Mobile {
        display: none;
      }
    }
  }

  &-Form {
    background-color: var(--color-background);
    border: 1px solid var(--color-background);
    border-radius: 10px;
    padding: 50px;
    overflow: scroll;
    height: 90vh;

    @include Mobile {
      background: var(--color-primary800);
      height: auto;
      margin-block: 10px;
    }

    .Input {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &-Info {
    background-color: var(--color-background);
    padding-inline: 50px;
    padding-block: 100px;
    text-align: center;
    width: 50%;

    @include Mobile {
      display: none;
    }

    svg {
      width: 100%;
      height: auto;
    }

    >video {
      width: 100%;
    }

    svg,
    p {
      margin-bottom: 60px;
    }

    h1 {
      margin-bottom: 20px;
    }
  }

  &-SubmitSuccess {
    z-index: -1;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    width: 400px;
    height: 300px;
    margin-left: -200px;
    margin-top: -150px;

    @include Mobile {
      width: 100%;
      height: 100vh;
      justify-content: center;
      margin: 0;
      position: initial;
    }

    &.__Success {
      opacity: 1;
      transition: all 300ms ease-in;
    }

    > a {
      display: flex;
    }
  }

  .container-fluid {
    padding: 0;
  }
}
