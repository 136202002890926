@import '../../assets/scss/mixins';

.ProfilePage {
  border: 1px solid var(--color-primary200);
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;

  @include Mobile {
    padding: 20px;
  }

  &-Section {
    display: flex;

    &__tfaStatus {
      display: flex;
      align-items: baseline;

      @include Mobile {
        display: block;
        margin-bottom: 10px;
      }
    }

    &__apiKeys {
      display: flex;
      align-items: baseline;

      @include Mobile {
        flex-flow: column wrap;
        gap: 10px;
        margin-bottom: 20px;
      }
    }
  }

  h3 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @include Mobile {
      font-size: large;
    }
  }

  p {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 10px;

    @include Mobile {
      font-size: small;
      margin-bottom: 20px;
    }
  }

  span {
    font-size: 15px;
    font-weight: initial;

    &.status {
      margin-left: 10px;
    }

    @include Mobile {
      &.status {
        margin-left: 0;
      }
    }
  }

  &-Image {
    margin-left: auto;
    align-self: center;

    @include Mobile {
      overflow: hidden;
      direction: rtl;
      width: 100%;
    }
  }

  button {
    @include Mobile {
      font-size: small;
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
