@import '../../assets/scss/mixins';

$addressheight: 60px;
$addressmargin: 10px;

.WithdrawPage {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include Mobile {
    gap: 10;
  }

  h3 {
    text-align: center;
  }

  &-Warning {
    display: flex;
    align-items: center;

    svg {
      width: 50px;
      height: 50px;
    }

    span {
      margin-left: 20px;
      font-size: small;
      color: var(--color-main);
    }
  }

  &-ButtonGroup {
    display: flex;

    button {
      width: 100%;
    }
  }

  &-AddressList {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: calc(5 * ($addressheight + $addressmargin));
    padding-bottom: 10px;

    .WithdrawAddressItem {
      &:not(:last-child) {
        margin-bottom: $addressmargin;
      }
    }
  }

  &-AddressSelect,
  &-AddressManage {
    background-color: var(--color-background);
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    height: $addressheight;
    font-size: small;

    &__slug {
      color: var(--color-main);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-AddressManage {
    display: flex;

    &__manageButtons {
      display: flex;
      gap: 20px;
      margin-left: auto;
      align-items: center;

      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;

        &:hover {
          // Delete address
          &:not(:last-child) {
            stroke: var(--color-error);
          }

          // Edit address
          &:last-child {
            fill: var(--color-main);
          }
        }
      }
    }
  }

  &-FeeInfo {
    display: flex;
    justify-content: space-between;
  }

  &-PercentButtons {
    display: flex;
    justify-content: space-around;

    button {
      background-color: white !important;
      width: 100%;
      min-height: auto;
      height: fit-content;
      font-size: medium;

      &:hover {
        background-color: var(--color-main) !important;
      }
    }
  }

  &-NoAddress {
    text-align: center;
  }

  &-BalanceInfo {
    text-align: center;
  }

  &-FormErrors {
    color: var(--color-error);
  }

  .preloader {
    margin: auto;
    margin-block: auto;
  }

  .Input {
    width: 100%;
  }
}
