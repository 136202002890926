.CreateApiKey,
.EditApiKey,
.DeleteApiKey,
.DisableApiKey {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;

  h2 {
    text-align: center;
  }

  input {
    width: 100%;
  }

  &-ButtonGroup {
    display: flex;

    button {
      width: 100%;
    }
  }

  &-Error {
    color: var(--color-error);
    text-align: center;
  }
}
