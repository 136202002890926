@import '../../assets/scss/mixins';

$mobileNavigationHeight: 80px;

.header {
  display: flex;
  flex: 1;
  margin-bottom:20px;
  align-items: center;
  overflow: visible;

  

  @media screen and (width <= 600px) {
    margin: 20px 0 10px;
  }

  & .header-column {
    &.center {
      flex: 1;
      flex-shrink: 0;
    }

    display: flex;
    align-items: flex-start;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .navbar-toggler {
    margin-left: 10px;
  }

  &-userEmail {
    @include Mobile {
      display: none;
    }
  }

  &-navbar {
    display: none;

    &.__mobile {
      z-index: 100;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $mobileNavigationHeight;
      background-color: $mainBackground;
      padding: 10px;
      gap: 15px;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size: 10px;
      display: none;

      @include Tablet {
        display: flex;

      }
    }

    &-item {
      overflow: auto;
      white-space: nowrap;
      width: 50px;

      a {
        text-decoration: none;

        &.active {
          svg {
            stroke: var(--color-main);
          }

          color: var(--color-main);
        }

        >div {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .navbar-collapse {
    flex-grow: initial;

    @include Mobile {
      display: none;
    }
  }
}

.headerDropdown {
  @include Mobile {
    width: 100%;
  }
}

.dropdown {
  &-toggle {
    @include Mobile {
      width: 100%;
    }
  }
}

.outlet-row {
  margin-bottom: $mobileNavigationHeight;

  @include Tablet {
    margin-bottom: 0;
  }

  @include Mobile {
    margin-bottom: $mobileNavigationHeight;

  }
}
