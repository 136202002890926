@import '../../../assets/scss/themes/colors.module';

.ExchangeModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;

  h2 {
    margin-top: 40px;
  }

  &-ExchangeIcon {
    background: black;
    width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 45px;
    padding: 10px;
    cursor: pointer;
    align-self: center;

    &.default {
      transition: rotate 300ms;
    }

    &.reversed {
      rotate: 360deg;
      transition: rotate 300ms;
    }
  }

  &-InputIcon {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  &-Rates {
    text-align: initial;

    span {
      color: $main;
      margin-left: auto;
    }

    p {
      display: flex;
    }
  }

  &-InputError {
    color: $error;
  }

  &-Input {
    display: flex;
    gap: 5px;

    .Select__small {
      .Select__control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &-SelectOption {
    overflow: hidden;
  }

  &-InputSign {
    position: absolute;
    align-self: center;
    display: flex;
    left: 10px;
    z-index: 20;
  }

  &-PreviewLoader {
    display: flex;
    justify-content: center;
    height: 200px;
    align-items: center;
  }

  &-Timer {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  &-PercentButtons {
    display: flex;
    justify-content: space-around;

    button {
      background-color: white !important;
      width: 100%;
      min-height: auto;
      height: fit-content;
      font-size: medium;

      &:hover {
        background-color: var(--color-main) !important;
      }
    }
  }

  &-ExchangeComplete {
    display: flex;
    align-items: center;
    background-color: var(--color-background);
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    height: 60px;

    // font-size: small;
    gap: 20px;
  }
}

.input-group {
  input,
  span {
    background-color: $mainBackground;
    border: 1px solid $mainBackground;
    border-radius: 5px;
    color: $white;
    caret-color: $main;
    height: 60px;
    padding-left: 25px;
    padding-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      border: 1px solid $main;
      background-color: $mainBackground;
      color: $white;
    }

    &:disabled {
      background-color: $primary700;
    }
  }
}
