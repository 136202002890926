$addressheight: 60px;
$addressmargin: 10px;

.WithdrawAddressItem {
  display: flex;
  background-color: var(--color-background);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px;
  height: $addressheight;
  font-size: small;
  gap: 20px;
  cursor: pointer;

  &:hover {
    border-color: var(--color-main);
  }

  &-Item {
    overflow: hidden;
  }

  &__slug {
    color: var(--color-main);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
