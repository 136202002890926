.TopUpFiatPage {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  

  &-Requirements {
    padding: 20px;
    list-style: disc;
    background: var(--color-primary700);
    border-radius: 10px;

    li {
      margin-left: 10px;
    }
  }
}

.WrapperFlexBetween {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
