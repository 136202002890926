.alert-container {
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 9999;

  .alert {
    width: 300px;
    max-width: 100vw;
    z-index: 9999;
    padding: 20px;
    border-radius: 4px;
    animation: Alert 1.2s ease;

    &.error {
      background-color: rgb(242 87 87 / 90%);
    }

    &.success {
      background-color: rgb(136 206 32 / 90%);
    }

    &.info {
      background-color: rgb(71 40 54 / 90%);
    }

    &.warning {
      background-color: rgb(242 205 96 / 90%);
    }

    & .close {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
    }
  }
}