.NavButton{
  border: 1px solid var(--color-primary);
  padding-inline: 10px;
  padding-block: 6px;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
  transition: all .25s ease;
  white-space: nowrap;
  display: flex;
  gap: 10px;
  align-items: center;
  
  &:hover {
    background-color: var(--color-main200);
    color: var(--color-black);
    border-color: transparent;
  }
  

  &.Active {
    border-color: transparent;
    background-color: var(--color-main);
    color: var(--color-black);
  }
}