@import '../../assets/scss/mixins';

.ApiKeysTable {
  margin-top: 40px;
  overflow: scroll;

  &-Actions {
    display: flex;
    margin-bottom: 40px;

    .ApiKeyButton {
      margin-left: auto;
      align-items: center;

      svg {
        stroke: var(--color-background);
        vertical-align: text-bottom;
      }
    }
  }

  &-DropDownDeleteButton {
    border: 1px solid var(--color-error);
    color: var(--color-error);
  }
}
