// Colors
$main: #f3ff00;
$main100: #fdffcc;
$main200: #faff99;
$main300: #f8ff66;
$main400: #f5ff33;
$main600: #f3ff00;
$main700: #c2cc00;
$main800: #929900;
$main900: #616600;
$primary: #7a7a7a;
$primary100: #e0e0e0;
$primary200: #c7c7c7;
$primary300: #adadad;
$primary400: #949494;
$primary600: #616161;
$primary700: #474747;
$primary800: #2e2e2e;
$primary900: #000;
$error: #f25757;
$success: #c2f970;
$warning: #f2cd60;
$additional: #472836;
$white: #fff;
$mainBackground: #000;

:root {
  --color-main: #f3ff00;
  --color-main100: #fdffcc;
  --color-main200: #faff99;
  --color-main300: #f8ff66;
  --color-main400: #f5ff33;
  --color-main600: #f3ff00;
  --color-main700: #c2cc00;
  --color-main800: #929900;
  --color-main900: #616600;
  --color-primary: #7a7a7a;
  --color-primary100: #e0e0e0;
  --color-primary200: #c7c7c7;
  --color-primary300: #adadad;
  --color-primary400: #949494;
  --color-primary600: #616161;
  --color-primary700: #474747;
  --color-primary800: #2e2e2e;

  // TODO: discuss with team, probably should just use --color-black
  --color-primary900: #000;
  --color-black: #000;
  --color-error: #f25757;
  --color-success: #c2f970;
  --color-warning: #f2cd60;
  --color-additional: #472836;
  --color-white: #fff;
  --color-background: #000;
  --color-green: #00BA88;
}

:export {
  main: $main;
  primary: $primary;
  error: $error;
  white: $white;
  success: $success;
  bg: $mainBackground;
  main900: $main900;
}
