.TextComponent {
  font-size: 16px;
  line-height: 22.4px;
  margin: 0;
  font-weight: 400;

  &.Bold {
    font-weight: 700;
  }

  &.Body2 {
    font-size: 14px;
    line-height: 19.6px;
  }

  &.Body3 {
    font-size: 12px;
    line-height: 16.8px;
  }

  &.Body4 {
    font-size: 10px;
    line-height: 14px;

    &.Bold {
      font-weight: 600;
    }
  }

  &.Capitalize {
    text-transform: capitalize;
  }

  &.Centered {
    text-align: center;
  }

  &.Light {
    font-weight: 300;
  }

  &.Placeholder {
    color: var(--color-primary);
  }
}