@import '../../assets/scss/mixins';

.searchInput {
  background: #616161;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #f3ff00;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  color: white;
  max-width: 200px;

  @include Mobile {
    width: 100%;
    max-width: initial;
  }
}
