

.RiskPercent {
  width: fit-content;
  background-color: transparent;
  border-radius: 99px;
  padding: 2.5px 10px;

  &.Low {
    background-color: var(--color-green);
  }

  &.High {
    background-color: var(--color-error);
  }
}