/* stylelint-disable no-descending-specificity */
@import 'assets/scss/themes/default';
@import 'ui/Input/input.styles';
@import 'ui/Menu/menu.styles';
@import 'components/Header/header.styles';
@import 'ui/Button/button.styles';
@import 'ui/Datepicker/datepickerModal.style';
@import 'src/styles/variables.module';

.PasswordRequirements {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: small;
  margin-bottom: 30px;
  max-width: 200px;
  align-content: center;

  &::before {
    flex-direction: column;
    flex: 1;
    display: flex;
    content: '';
    min-width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-right: 10px;
  }

  p {
    text-align: left;
    margin: 0;
  }

  &.Complete {
    &::before {
      background: var(--color-success);
    }
  }

  &.Incomplete {
    &::before {
      background: var(--color-error);
    }
  }
}

// table styles
.table-badge {
  flex: 1;
  padding: 5px;
  border-radius: 10px;
  background-color: $primary700;
}

.table {
  td,
  th {
    color: #fff;
  }

  td {
    font-size: 14px;
    border-bottom-color: $primary700;
    height: 60px;
    vertical-align: middle;
    background-color: transparent;
  }

  th {
    background-color: $primary800;
    font-size: 12px;
    font-weight: normal;
    border-bottom-width: 0;
  }
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px;
  text-transform: capitalize;

  &.status-success {
    background-color: $success;
  }

  &.status-pending {
    background-color: $warning;
  }

  &.status-failed {
    background-color: $error;
  }

  &.status-neutral {
    background-color: $primary300;
  }
}

body {
  // background-color: $mainBackground;
  background: #000;
}

.link {
  color: $main200;

  &:visited {
    color: $main200;
  }

  &:hover {
    color: $main;
  }
}

// .with-bg {
//   background-image: url('/assets/images/decor.svg');
//   background-repeat: repeat-x;
//   background-position: 0 60%;
// }

.nav-pills {
  display: flex;
  flex-direction: row;

  & .pill,
  &.pill {
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    border: 1px solid $primary;
    background: $mainBackground;
    color: $white;

    &:hover {
      background-color: $primary700;
      border: 1px solid transparent;

      a {
        color: $white;
      }

      button {
        color: $white;
      }
    }

    &.active {
      border: 1px solid transparent;
      background-color: $main;

      a,
      &.pill {
        color: $mainBackground;
        text-decoration: none;
      }
    }

    & a,
    &.pill {
      text-decoration: none;
    }
  }
}

// .login-form {
//   background-color: $primary800;
//   padding: 40px 80px;
//   border-radius: 5px;
//   box-shadow: 0 20px 50px rgb(0 0 0 / 40%);
// }

.balance-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .balance-info-icon {
    margin-right: 10px;
  }

  & .balance-info-data {
    & p {
      margin: 0;
    }

    @include Mobile {
      margin-right: 15px;
    }
  }
}

.period-selector {
  background: transparent;
}

.t-row {
  transition: all 0.3s ease-in-out;
  opacity: 1;

  &.hover {
    &:hover {
      background: $primary600;
      cursor: pointer;
    }
  }

  &.table-load {
    opacity: 0.5;
  }
}


.sidebar-scene {
  position: fixed;
  right: 0;
  top: 0;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  transform: translateX(0);
  overflow: scroll;
  z-index: 100;

  @include Mobile {
    padding: 0;
  }

  & .overlay-scene {
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: rgb(0 0 0 / 70%);
  }

  & .sidebar-scene-page {
    z-index: 9999;
    align-items: center;
    align-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    min-height: 100vh;
    transform: translateX(500px);
    background-color: $primary800;
    max-width: 100%;

    @include Mobile {
      width: 85%;
      padding: 10px;
    }

    & .side-header {
      background: $primary900;
      padding: 20px;
      width: 100%;

      & p {
        margin: 0;
        font-size: 1em;
      }
    }
  }
}

.slide-left-enter {
  & .overlay-scene {
    opacity: 0;
  }

  & .sidebar-scene-page {
    transform: translateX(500px);
  }
}

.slide-left-enter-active {
  & .overlay-scene {
    opacity: 1;
    transition: all 300ms ease-in;
  }

  & .sidebar-scene-page {
    transform: translateX(0);
    transition: all 300ms ease-in;
  }
}

.slide-left-appear {
  & .overlay-scene {
    opacity: 0;
  }

  & .sidebar-scene-page {
    transform: translateX(500px);
  }
}

.slide-left-appear-active {
  & .overlay-scene {
    opacity: 1;
    transition: all 300ms ease-in;
  }

  & .sidebar-scene-page {
    transform: translateX(0);
    transition: all 300ms ease-in;
  }
}

.slide-left-enter-done {
  & .overlay-scene {
    opacity: 1;
    transition: all 300ms ease-in;
  }

  & .sidebar-scene-page {
    transform: translateX(0);
  }
}

.slide-left-exit {
  & .overlay-scene {
    opacity: 1;
  }

  & .sidebar-scene-page {
    transform: translateX(0);
  }
}

.slide-left-exit-active {
  & .overlay-scene {
    opacity: 0;
    transition: all 300ms ease-in;
  }

  & .sidebar-scene-page {
    transform: translateX(500px);
    transition: all 300ms ease-in;
  }
}

.fade-exit {
  transform: translateX(0);
}

.fade-exit-active {
  transform: translateX(500);
  transition: opacity 300ms ease-in;
}

.fade-exit-done {
  transform: translateX(500);
}

.icon-dd {
  margin-inline: 20px;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 30px;

  &.show {
    background-color: $main;

    .profile-icon {
      fill: $mainBackground;
      stroke: none;
    }
  }

  .profile-icon {
    fill: $white;

    &--active {
      fill: $main;

      &:hover {
        fill: $mainBackground;
      }
    }
  }

  &::after {
    display: none;
  }

  &:hover {
    background-color: $main;

    .profile-icon {
      fill: $mainBackground;
    }
  }
}

.citron-colors {
  td:nth-child(even) {
    color: $main100;
  }

  td:nth-child(odd) {
    color: $main;
  }

  td:first-child {
    color: $white;
  }
}

.tr-info {
  a,
  p {
    display: block;
    flex-wrap: wrap;
    font-size: 0.8em;
    margin-bottom: 10px;
  }
}

.form-control {
  color: $white;
  background: $primary700;
  border-color: $primary400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $primary400;
    opacity: 1; /* Firefox */
  }
}

.card {
  background: $mainBackground;
  border-color: $primary200;
  padding: 20px;

  & .card-body {
    // background: $mainBackground;
  }
}

.dropdown-menu-dark {
  background: $primary800;
  padding-left: 10px;
  padding-right: 10px;

  & .dropdown-item {
    border-radius: 5px;
    background: $mainBackground;
    margin-bottom: 3px;
    margin-top: 3px;

    &:hover {
      background: $main;
      color: $mainBackground;
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;

    // transform: scale(1);
  }

  50% {
    opacity: 1;

    // transform: scale(1.05);
  }

  100% {
    opacity: 0;
  }
}

.preloader {
  .item-loader {
    animation: FadeIn 1.2s ease infinite;
  }

  @for $i from 1 through 6 {
    .item-loader {
      &:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.2}s;
      }
    }
  }
}

@keyframes Alert {
  0% {
    opacity: 0;
    transform: translateX(500px) scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.ctrn-select {
  box-sizing: border-box;

  & .dropdown-toggle {
    height: 38px;
    background-color: #474747;
    border-color: #949494;
    text-align: left;
    padding-left: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &.show {
      border-color: #f3ff00;
      cursor: pointer;
    }
  }

  & .dropdown-menu {
    background-color: $primary700;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 15%);

    &.show {
      width: calc(100% - 20px);
    }
  }

  &.focused {
    border-color: #f3ff00;
  }
}

.ButtonGroup {
  display: flex;

  button {
    width: 100%;
  }
}

.InputError {
  color: $error;
}
