@mixin breakpoint($point) {
  @if $point == 'xs' {
    @media only screen and (width <= 599px) {
      @content;
    }
  }

  @if $point == 'sm' {
    @media only screen and (width >= 600px) {
      @content;
    }
  }

  @if $point == 'md' {
    @media only screen and (width >= 900px) {
      @content;
    }
  }

  @if $point == 'lg' {
    @media only screen and (width >= 1200px) {
      @content;
    }
  }

  @if $point == 'xl' {
    @media only screen and (width >= 1800px) {
      @content;
    }
  }
}
