.Table {
  width: 100%;
  overflow-x: auto;

  th {
    padding-inline: 10px;
    padding-block: 5px;
    background-color: var(--color-primary800);
    white-space: nowrap;
    min-height: 40px;
    height: 40px;
  }

  td {
    padding-block: 20px;
    padding-inline: 10px;
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--color-primary700);
    }
  }

  .Header {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}