.ColoredStatusCircle {
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 99px;

  &.success {
    background-color: var(--color-success);
  }

  &.pending {
    background-color: var(--color-warning);
  }

  &.failed {
    background-color: var(--color-error);
  }
}