.Checkbox {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid var(--color-main900);
  background-color: var(--color-primary800);
  background-position: center;
  cursor: pointer;
  transition: all 0.15s ease;
  border-radius: var(--border-radius);


  &:hover {
    border-color: var(--color-main);
  }
  
  &:checked {
    background-image: url('/assets/svg/CheckIcon.svg');
    background-repeat: no-repeat;
    border-color: var(--color-main);
  }

  &:disabled {
    cursor: not-allowed;
    border-color: transparent;
  }
}