@import '../../assets/scss/mixins';

.AddressesPage {
  &-Actions {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;

    @include Mobile {
      align-items: center;
    }

    .primary {
      margin-left: auto;

      @include Mobile {
        width: 100%;
      }
    }
  }

  &-Filters {
    display: flex;
    gap: 10px;

    @include Mobile {
      display: none;
    }
  }

  .btn-group {
    height: 25px;
    align-self: center;
  }
}
