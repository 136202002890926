.Switch {
  border: 1px solid transparent;
  width: fit-content;
  border-radius: 14px;

  &.On {
    border-color: var(--color-main);
  }

  &.Off {
    border-color: var(--color-white);
  }
}