@import "src/assets/scss/mixins";

.BaseSideBarComponent {
  width: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  &-Header {
    display: flex;
    align-items: center;
    padding: 20px;
    background: var(--color-primary900);
    min-height: 60px;
    gap: 10px;
    margin-bottom: 40px;

    @include Mobile {
      margin-bottom: 10px;
    }

    .CloseIcon {
      margin-left: auto;

      &:hover {
        cursor: pointer;
        stroke: var(--color-main);
      }

      &:active {
        stroke: var(--color-main);
      }
    }
  }
}
