.WrapperFlex {
  display: flex;
  align-items: center;
}

.PageActionsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

.WrapperFlexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WrapperColumn {
  display: flex;
  flex-direction: column;
}