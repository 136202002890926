@import '../../../../assets/scss/themes/colors.module';

.DeleteUserModal {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;

  &-Heading {
    text-align: center;
  }

  &-UserInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &-ButtonGroup {
    display: flex;
    margin-top: 20px;

    button {
      width: 100%;
    }

    .SecondaryButton {
      background-color: $main100;

      &:hover {
        background-color: $main200;
      }
    }
  }
}
