@import 'src/assets/scss/mixins';

.TransactionList {
  .Table {
    overflow-x: auto;
    min-height: 100vh;
    margin-top: 25px;
  }

  table {
    th {
      padding-inline: 10px;
      padding-block: 5px;
      background-color: var(--color-primary800);
      white-space: nowrap;
    }

    td {
      padding-block: 20px;
      padding-inline: 10px;
    }

    tbody {
      tr {
        border-bottom: 1px solid var(--color-primary700);
      }
    }

    .Header {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .TransactionId {
    p {
      @include TextOverflow;

      text-decoration: underline;
      max-width: 170px;
      color: var(--color-main100);
      cursor: pointer;
    }

  }
}