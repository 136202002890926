@import '../../../assets/scss/themes/colors.module';

.CreateBalanceReport {
  display: flex;
  flex-direction: column;
  flex: auto;

  h2,
  p {
    text-align: center;
    margin-top: 30px;
  }

  button {
    margin-top: auto;
    margin-right: 0;
  }

  .InputError {
    text-align: center;
  }
}
