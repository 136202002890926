.UnprotectedPage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background-image: url('/assets/images/decor.svg');
  background-repeat: repeat-x;
  background-position: 0 60%;

  &-Logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    svg {
      width: 100%;
    }

    @media screen and (width <= 600px) {
      svg {
        height: 50px;
      }
    }
  }

  &-Outlet {
    background-color: var(--color-primary800);
    border-radius: 5px;
    padding: 40px 80px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 20px 50px rgb(0 0 0 / 40%);

    @media screen and (width <= 600px) {
      padding: 20px;
    }
  }

  h3,
  p {
    margin-bottom: 20px;
    text-align: center;
  }

  button,
  .Input {
    width: 100%;
  }

  .Input {
    margin-bottom: 40px;
  }
}
