@import 'src/assets/scss/mixins';

.menu-wrapper {
  &.inModal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex:1;

    & .menu-button {
      display: flex;
      flex-direction: row;
      line-height: 45px;
      padding: 0 10px;
      margin-right: 15px;
      border-radius: 4px;
      transition: all .3s ease-in-out;
      text-decoration: none;
      font-size: 1.8em;
      color: #FFF;
      align-items: center;
      justify-content: center;
    }
  }

  &.main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: auto;
    padding-block: 10px;

    @include Tablet {
      display: none;
    }


    & .menu-button{
      display: flex;
      flex-direction: column;
      line-height: 45px;
      padding: 0 10px;
      margin-right: 15px;
      border-radius: 4px;
      transition: all .3s ease-in-out;
      text-decoration: none;
      font-size: 1em;
      color: #FFF;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: $main;
        color: $mainBackground;

        &:hover{
          background-color: $main700;
        }
      }

      &:hover{
        background-color: $primary100;
        color: $mainBackground;
      }
    }
  }
}
