.OTPCodeInput {
  display: flex;
  flex-direction: column;
  gap: 40px;
  h2 {
    text-align: center;
  }

  .form-label {
    text-align: center;
    padding-inline: 20px;
    margin-bottom: 25px;
  }

  &-ButtonGroup {
    display: flex;

    button {
      width: 100%;
    }
  }
}
