a {
  &.ctr-btn {
    display: block;
    text-align: center;
    width: 100%;
    margin: 0;
  }
}

.ctr-btn {
  line-height: 1.5em;
  min-height: 45px;
  padding: 0 10px;
  margin-right: 15px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-size: 1.1em;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  &:last-of-type {
    margin-right: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  // &:active:not(:disabled) {
  //   transform: translateY(2px);
  // }

  &.btn-sm {
    min-height: 25px;
  }

  &.primary {
    background-color: $main;
    color: $mainBackground;

    &.businessSignUp{
      display: flex;
    }

    &:hover {
      background-color: $main700;
    }

    &:disabled {
      background-color: var(--color-main100);
      color: var(--color-primary200);

      &:hover {
        background-color: var(--color-main100);
      }
    }

    &.btn-xl {
    }

    &.btn-sm {
      font-size: 0.8em;
      min-height: 25px;
    }
  }

  &.secondary {
    background-color: var(--color-main100);
    border: 1px solid var(--color-main);
    color: $mainBackground;

    &:hover {
      background-color: var(--color-main200);
    }

    &:active {
      background-color: var(--color-main700);
    }

    &.btn-xl {
    }

    &.btn-sm {
      font-size: 1em;
      line-height: 25px;
    }
  }

  &.filter {
    background-color: $primary800;
    color: $main;

    &:hover {
      background-color: $primary600;
    }

    &.btn-xl {
    }

    &.btn-sm {
      font-size: 0.8em;
      line-height: 25px;
      border-radius: 15px;
    }
  }

  &.link {
    background: none;
    color: $white;
    align-items: center;
    display: flex;
    padding: 0;

    p {
      margin: 0;
    }

    &:hover {
      color: $main;
    }

    &.btn-xl {
      font-size: 14px;
    }

    &.btn-sm {
      font-size: 12px;
    }

    &:disabled {
      opacity: 0.5;
      color: $white;
      background: transparent;

      &:hover {
        color: $white;
        border-color: transparent;
      }
    }
  }

  // &.disabled {
  //   opacity: 0.5;
  //   color: $white;
  //   background: transparent;

  //   &:hover {
  //     color: $white;
  //     border-color: transparent;
  //   }
  // }

  &.inactive {
    color: $white;
    background: transparent;
    border: 1px solid $primary400;

    &:hover {
      color: $mainBackground;
      border-color: transparent;
    }
  }

  &.wide {
    width: 100%;
  }
}
