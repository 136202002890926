@import '../../assets/scss/mixins';

.payinPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .qrWrapper {
    display: flex;
    background: white;
    padding: 20px;
    border-radius: 30px;
    width: 220px;
    margin-bottom: 20px;
  }

  & .address {
    font-size: 0.8em;
    display: flex;
    width: 350px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: var(--color-primary700);
    padding: 5px 10px 5px 15px;

    .btn {
      border-radius: 20px;
    }

    & p {
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .doneBtn {
    display: flex;
    margin: 40px 0;
    align-self: center;
  }

  & .info {
    display: flex;
    align-items: center;
    color: var(--color-main);
    padding: 20px;

    svg {
      width: 90px;
    }

    @include Mobile {
      padding: 0;
      padding-block: 10px;
    }
  }

  .memo {
    margin-block: 15px;
    font-size: 0.7em;
  }

  .Select {
    min-width: 300px;
  }
}

