@import '../../assets/scss/themes/colors.module';
@import '../../assets/scss/mixins';

.SideBarModal {
  position: fixed;
  right: 0;
  top: 0;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  transform: translateX(0);
  overflow: hidden;
  z-index: 998;

  &-Overlay {
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: rgb(0 0 0 / 70%);
  }

  &-Content {
    height: 100dvh;
    max-width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    transform: translateX(500px);
    background-color: $primary800;
    overflow-y: auto;

    @include Mobile {
      width: 85%;
      padding: 10px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  &-Header {
    display: flex;
    align-items: center;
    padding: 20px;
    background: $primary900;
    min-height: 60px;
    gap: 10px;

    .CloseIcon {
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }

      &:active {
        stroke: $main;
      }
    }
  }
}

.slide-left-enter {
  .SideBarModal {
    &-Overlay {
      opacity: 0;
    }

    &-Content {
      transform: translateX(500px);
    }
  }
}

.slide-left-enter-active {
  .SideBarModal {
    &-Overlay {
      opacity: 1;
      transition: all 300ms ease-in;
    }

    &-Content {
      transform: translateX(0);
      transition: all 300ms ease-in;
    }
  }
}

.slide-left-enter-done {
  .SideBarModal {
    &-Overlay {
      opacity: 1;
      transition: all 300ms ease-in;
    }

    &-Content {
      transform: translateX(0);
    }
  }
}

.slide-left-exit {
  .SideBarModal {
    &-Overlay {
      opacity: 1;
    }

    &-Content {
      transform: translateX(0);
    }
  }
}

.slide-left-exit-active {
  .SideBarModal {
    &-Overlay {
      opacity: 0;
      transition: all 300ms ease-in;
    }

    &-Content {
      transform: translateX(500px);
      transition: all 300ms ease-in;
    }
  }
}
