.balance {
  .actionButton {
    @media screen and (max-width: 600px){
      font-size: 0 !important;
    }
  }

  td {
    background-color: transparent;
  }
}
