@import '../../../../assets/scss/themes/colors.module';

.AddNewUserModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;

  span {
    color: red;
  }

  &-UserEmail {
    background: $primary700;
    border-radius: 10px;
    padding: 20px;
    color: $white;
    border: none;
  }

  &-ButtonGroup {
    display: flex;
    margin-top: 20px;

    button {
      width: 100%;
    }

    .SecondaryButton {
      background-color: $main100;

      &:hover {
        background-color: $main200;
      }
    }
  }
}
