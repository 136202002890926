@import '../../assets/scss/mixins';

.Pagination {
  display: flex;
  margin-top: 50px;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;

  @include Mobile {
    margin-bottom: 40px;
  }

  &.right {
    justify-content: flex-end;
  }

  .Paginate {
    display: flex;
    gap: 5px;
    list-style: none;
    padding-left: 0;
    margin: 0;

    .PageItem {
      @include BorderBox;
    
      text-decoration: none;
      display: flex;
      gap: 5px;
      align-items: center;
      padding-block: 5px;
      padding-inline: 10px;
      font-size: 12px;
      line-height: 20px;
      text-align: center;

      &:hover {
        transition: background-color 0.15s ease-out;
        background-color: var(--color-main300);
        color: var(--color-primary900);
      }
    
    }
  
    .NextBackButtons {
      @include BorderBox;
    
      text-decoration: none;
      display: flex;
      gap: 5px;
      align-items: center;
      padding-block: 5px;
      padding-inline: 10px;
      font-size: 12px;
      line-height: 20px;

      &:hover {
        transition: background-color 0.15s ease-out;
        background-color: var(--color-main300);
        color: var(--color-primary900);

        svg {
          fill: var(--color-primary900);
        }
      }
    
    }

    .ActivePage {
      font-weight: 700;
      background-color: var(--color-main);
      color: var(--color-primary900);
    }
  }

  .RowsPerPage {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .items-per-page {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  @include Tablet {
    display: flex;
    gap: 20px;
    align-items: center;

  }
}

.page {
  @include Tablet {
    &-link {
      padding: 0.4rem;
    }
  }
}
