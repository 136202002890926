.CreateApiKey {
  &-InfoList {
    padding: 20px;
    background-color: var(--color-primary700);
    border-radius: 10px;

    li {
      margin-left: 20px;
    }
  }

  &-InfoSecret {
    text-align: center;
  }
}
