@import '../../../assets/scss/themes/colors.module';

.CreateTransactionReport {
  display: flex;
  flex-direction: column;
  flex: auto;

  &-Header {
    display: flex;
    align-items: center;
    padding: 20px;
    background: $primary900;
    min-height: 60px;

    .CloseIcon {
      margin-left: auto;
    }
  }

  h2,
  p {
    text-align: center;
    margin-top: 30px;
  }

  button {
    margin-top: auto;
    margin-right: 0;
  }
}
