.TableSortingArrow {
  background-color: transparent;
  border: none;
  padding: 0;

  svg {
    border-radius: 99px;
    transition: all 300ms ease;
  }

  &:hover {
    svg {
      stroke: var(--color-main200);
    }
  }

  &.Active {
    svg {
      stroke: var(--color-main);
    }

  }

  &.Ascending {
    svg {
      transform: rotate(-180deg);
    }
  }

  &.Descending {
    svg {
      transform: rotate(0deg);
    }
  }
}