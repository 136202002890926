.WithdrawAccount {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  height: 60px;
  background: var(--color-primary900);
  border-radius: 5px;
  cursor: pointer;

  .View {
    display: flex;
    gap: 20px;
    align-items: center;
    overflow: hidden;


    .Info {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .Name {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .IBAN {
        color: var(--color-main)
      }
    }
  }
}