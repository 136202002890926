@import '../../assets/scss/themes/colors.module';

.TeamPage {
  // display: flex;
  margin-bottom: 40px;
  align-items: center;

  h3 {
    margin-right: 40px;
  }

  &-Heading {
    align-items: center;
    display: flex;
    margin-bottom: 30px;
  }

  &-Menu {
    border: 1px solid transparent;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
      fill: $mainBackground;
      background-color: $main;
    }
  }

  &-Filters {
    display: flex;
    margin-bottom: 30px;

    button {
      margin-left: auto;
    }
  }

  &-StatusColumn {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 10px;
      margin-right: 5px;
    }

    &.Active {
      &::before {
        background: $success;
      }
    }

    &.Deactivated {
      &::before {
        background: $error;
      }
    }
  }

  .menu-button {
    display: flex;
    flex-direction: column;
    line-height: 45px;
    padding: 0 10px;
    margin-right: 15px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-size: 1.1em;
    color: #fff;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: $main;
      color: $mainBackground;

      &:hover {
        background-color: $main700;
      }
    }

    &:hover {
      background-color: $primary100;
      color: $mainBackground;
    }
  }

  // the menu button should come from somewhere externally, so as of now just adding more styles here
  .menu-button {
    border: 1px solid $primary;
    line-height: 35px;

    &.active {
      border: none;
    }
  }
}
