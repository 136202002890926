.TableFilterIcon {
  background-color: transparent;
  border: none;
  padding: 0;

  svg {
    border-radius: 99px;
    transition: all 300ms ease;
    fill: transparent;
    stroke: var(--color-white);

    &:hover {
      stroke: var(--color-main200)
    }
  }

  &.Active {
    svg {
      stroke: var(--color-main);
    }

  }
}