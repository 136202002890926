.BalanceBorder{
  background-color: var(--color-black);
  border-radius: 5px;
  padding: 20px 10px;
  display: flex;

  .Borders {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;

    .Border {
      display: flex;
      align-items: center;
      gap: 10px;

      :first-child {
        color: var(--color-main);
        margin-right: auto;
      }

      .Delete {
        &:hover {
          cursor: pointer;
          stroke: var(--color-error);
        }
      }

    }
  }

  .Currency {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}