/* stylelint-disable scss/at-mixin-pattern */

@mixin Mobile {
  @media (width <= 600px) {
    @content;
  }
}

@mixin Tablet {
  @media (width <= 810px) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin Desktop {
  @media (width >= 992px) {
    @content;
  }
}

@mixin TextOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin Circle {
  &::before {
    content: '';
    min-width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-right: 10px;
  }
}

@mixin ValidationCircle {
  @include Circle;

  &::before {
    background: var(--color-white);
  }
}

@mixin ValidationSuccessCircle {
  &::before {
    background: var(--color-success);
  }
}

@mixin ValidationErrorCircle {
  &::before {
    background: var(--color-error);
  }
}

@mixin BorderBox {
  border: 1px solid var(--color-primary700);
  border-radius: var(--border-radius);
}
