@import '../../../assets/scss/mixins';

.ProfileChangePassword {
  padding: 40px;

  @include Mobile {
    padding: 20px;
  }

  .Input {
    margin-bottom: 20px;
  }

  .PasswordRequirements {
    margin-block: 10px;
  }

  button {
    width: 100%;
  }
}
