.ModalOTPInput {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  text-align: center;

  .form-label {
    padding-inline: 20px;
    margin-bottom: 25px;
  }

  &-ButtonGroup {
    display: flex;

    button {
      width: 100%;
    }
  }
}
